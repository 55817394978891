import { IDisplayGroup } from '@/modules/groups';
import type { MeFragmentFragment } from '@/modules/users/graphql/users.generated';
import { gql } from '@apollo/client';
import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
import * as Sentry from '@sentry/remix';
import { getAnalytics, logEvent, setUserId, setUserProperties } from 'firebase/analytics';
import { ReactNode, createContext, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Role } from '../graphql/types';
import { app } from '../plugin/firebase';
import { AnalyticsEvent, ContentType } from '../utils/analytics/event';
import {
  type ApplicationProvider_CompanySettingFragment,
  useApplicationProviderQuery,
} from './ApplicationContext.generated';

type EventParamType = {
  contentType: ContentType;
  [key: string]: unknown;
};

const pseudoOfficeId = 0;
const pseudoProjectId = 0;

export type ApplicationContextProps = {
  companySetting?: ApplicationProvider_CompanySettingFragment;
  me?: MeFragmentFragment;
  isCompanyOrOfficeAdmin: boolean;
  isAdmin: boolean;
  officeId?: number;
  projectId?: number;
  traceLogEvent: (eventName: AnalyticsEvent, eventParams: EventParamType) => void;
  groupsByUser: IDisplayGroup[];
  isApplicationLoading: boolean;
};

export type ApplicationProps = {
  children: ReactNode;
};

export const ApplicationContext = createContext<ApplicationContextProps>({
  me: undefined,
  companySetting: undefined,
  isCompanyOrOfficeAdmin: false,
  isAdmin: false,
  traceLogEvent: () => {},
  isApplicationLoading: true,
  groupsByUser: [],
});

export const useApplicationContext = () => useContext(ApplicationContext);

gql`
  fragment ApplicationProvider_CompanySetting on CompanySetting {
    maxAssetHierarchyLevel
    accessSchedule
    accessSuggest
    accessAiVoice
    accessCheckList
    accessRequest
    accessManual
    accessProduct
    accessGroup
    reportBaseDateType
    assetHierarchies {
      label
      level
    }
    customWorkOrderStatuses {
      status
      label
    }
    bottomNavigationItems {
      type
    }
  }
  query ApplicationProvider {
    companySetting {
      ...ApplicationProvider_CompanySetting
    }
    me {
      ...meFragment
    }
  }
`;

const hasAdminRole = (roles: { role: Role }[]): boolean =>
  roles.some((role) => role.role.position === 'admin');

export const ApplicationProvider = ({ children }: ApplicationProps) => {
  const { data, loading: isLoading } = useApplicationProviderQuery();

  const me = data?.me;
  const officeId = me?.offices?.[0]?.id ?? pseudoOfficeId;
  const projectId = me?.offices?.[0]?.projects?.[0]?.id ?? pseudoProjectId;
  const companySetting = data?.companySetting;
  const groupsByUser = me?.groups || [];
  const isCompanyOrOfficeAdmin = !!(
    me &&
    (hasAdminRole(me.companyRoles) || hasAdminRole(me.officeRoles))
  );
  const isAdmin = !!(me && (isCompanyOrOfficeAdmin || hasAdminRole(me.projectRoles)));
  const { i18n } = useTranslation();

  useEffect(() => {
    if (me === undefined) return undefined;

    const analytics = getAnalytics(app);
    Sentry.setUser({ id: me.id, email: me.email, username: me.name });
    datadogRum.setUser({
      id: me.id,
      name: me.name,
      email: me.email,
    });
    datadogLogs.setUser({
      id: me.id,
      name: me.name,
      email: me.email,
    });

    setUserId(analytics, me.id);
    setUserProperties(analytics, {
      userId: me.id,
      companyId: me.company.id,
    });
    if (me.locale && i18n.language !== me.locale) {
      i18n.changeLanguage(me.locale);
    }
  }, [me, i18n]);

  const traceLogEvent = (eventName: string, eventParams: EventParamType) => {
    logEvent(getAnalytics(app), eventName, eventParams);
  };

  const value = {
    companySetting,
    me,
    isCompanyOrOfficeAdmin,
    isAdmin,
    officeId,
    projectId,
    traceLogEvent,
    groupsByUser: groupsByUser ?? [],
    isApplicationLoading: isLoading,
  };

  return <ApplicationContext.Provider value={value}>{children}</ApplicationContext.Provider>;
};
